import {
  SCRAPPERDATA,
  LOGINDATA,
  ADDUSERDATA,
  PRODUCTSDATA,
  OFFERSDATA,
  PRODUCT_INFO,
} from "../constants/index";

export const loadScrapper = (page) => ({
  type: SCRAPPERDATA.LOAD,
  page,
});

export const getcredentials = (payload) => ({
  type: LOGINDATA.LOGIN_LOADING,
  payload,
});
export const addUser = (payload) => ({
  type: ADDUSERDATA.ADDUSER_LOADING,
  payload,
});
export const initallAddUserState = () => ({
  type: ADDUSERDATA.ADDUSER_INIT,
});
export const logout = () => ({
  type: "USER_LOGGED_OUT",
});
export const clearPageData = () => ({
  type: SCRAPPERDATA.CLEAR,
});
export const addPageData = (payload) => ({
  type: SCRAPPERDATA.ADD,
  payload,
});
export const getuserdata = (payload) => ({
  type: LOGINDATA.LOGIN_DATA,
  payload,
});
export const getadduserdata = (payload) => ({
  type: ADDUSERDATA.ADDUSER_DATA,
  payload,
});

export const getadduserError = (error) => ({
  type: ADDUSERDATA.ADDUSER_FAIL,
  error,
});

export const setStat = (stat, date, page) => ({
  type: SCRAPPERDATA.LOAD,
  stat,
  page,
  date,
});
export const setScrapper = () => ({
  type: SCRAPPERDATA.LOAD_SUCCESS,
});
export const setError = (error) => ({
  type: SCRAPPERDATA.LOAD_FAIL,
  error,
});
export const setLoginError = (error) => ({
  type: LOGINDATA.LOGIN_FAIL,
  error,
});
export const getData = (payload) => ({
  type: SCRAPPERDATA.LOAD_DATA,
  payload,
});

export const requestProducts = (params) => ({
  type: PRODUCTSDATA.REQUEST_PRODUCTS,
  params,
});

export const requestProduct = (id) => ({
  type: PRODUCTSDATA.REQUEST_PRODUCT,
  id,
});

export const requestProductSuccess = (payload) => ({
  type: PRODUCTSDATA.REQUEST_PRODUCT_SUCCESS,
  payload,
});

export const requestProductError = (error) => ({
  type: PRODUCTSDATA.REQUEST_PRODUCT_FAILED,
  error,
});

export const requestProductsSuccess = (payload) => ({
  type: PRODUCTSDATA.REQUEST_PRODUCTS_SUCCESS,
  payload,
});

export const requestProductsError = (error) => ({
  type: PRODUCTSDATA.REQUEST_PRODUCTS_FAILED,
  error,
});

export const requestMappedProduct = (id) => ({
  type: OFFERSDATA.REQUEST_MAPPED_PRODUCT,
  id,
});

export const requestMappedProductSuccess = (payload) => ({
  type: OFFERSDATA.REQUEST_MAPPED_PRODUCT_SUCCESS,
  payload,
});

export const requestMappedProductError = (error) => ({
  type: OFFERSDATA.REQUEST_MAPPED_PRODUCT_FAILED,
  error,
});

export const resetMappedProduct = () => ({
  type: OFFERSDATA.RESET_MAPPED_PRODUCT,
});

export const requestOffers = (params) => ({
  type: OFFERSDATA.REQUEST_OFFERS,
  params,
});

export const requestOffersSuccess = (payload) => ({
  type: OFFERSDATA.REQUEST_OFFERS_SUCCESS,
  payload,
});

export const requestOffersError = (error) => ({
  type: OFFERSDATA.REQUEST_OFFERS_FAILED,
  error,
});

export const requestOffer = (id) => ({
  type: OFFERSDATA.REQUEST_OFFER,
  id,
});

export const searchOffers = (params) => ({
  type: OFFERSDATA.SEARCH_OFFERS,
  params,
});

export const requestOfferSuccess = (payload) => ({
  type: OFFERSDATA.REQUEST_OFFER_SUCCESS,
  payload,
});

export const requestOfferError = (error) => ({
  type: OFFERSDATA.REQUEST_OFFER_FAILED,
  error,
});

export const resetProducts = () => {
  return {
    type: PRODUCTSDATA.RESET_PRODUCTS,
  };
};

export const resetProduct = () => {
  return {
    type: PRODUCTSDATA.RESET_PRODUCT,
  };
};

export const resetOffers = () => {
  return {
    type: OFFERSDATA.RESET_OFFERS,
  };
};

export const resetOffer = () => {
  return {
    type: OFFERSDATA.RESET_OFFER,
  };
};

export const setOffersViewFilters = (payload) => {
  return {
    type: OFFERSDATA.SET_OFFERS_VIEW_FILTERS,
    payload,
  };
};

export const setProductsViewFilters = (payload) => {
  return {
    type: PRODUCTSDATA.SET_PRODUCTS_VIEW_FILTERS,
    payload,
  };
};

export const mapOffers = (offers, product, query) => {
  return {
    type: PRODUCTSDATA.MAP_OFFERS,
    offers,
    product,
    query,
  };
};

export const mapOffersSuccess = (payload) => {
  return {
    type: PRODUCTSDATA.MAP_OFFERS_SUCCESS,
    payload,
  };
};

export const mapOffersError = (error) => {
  return {
    type: PRODUCTSDATA.MAP_OFFERS_FAILED,
    error,
  };
};

export const createProduct = (offer) => {
  return {
    type: OFFERSDATA.CREATE_PRODUCT,
    offer,
  };
};

export const createProductSuccess = (payload) => {
  return {
    type: OFFERSDATA.CREATE_PRODUCT_SUCCESS,
    payload,
  };
};

export const createProductError = (error) => {
  return {
    type: OFFERSDATA.CREATE_PRODUCT_FAILED,
    error,
  };
};

export const deleteProduct = (id) => {
  return {
    type: PRODUCTSDATA.DELETE_PRODUCT,
    id,
  };
};

export const deleteProductSuccess = () => {
  return {
    type: PRODUCTSDATA.DELETE_PRODUCT_SUCCESS,
  };
};

export const deleteProductError = (error) => {
  return {
    type: PRODUCTSDATA.DELETE_PRODUCT_FAILED,
    error,
  };
};

export const updateOffer = (id, data) => {
  return {
    type: OFFERSDATA.UPDATE_OFFER,
    id,
    data,
  };
};

export const updateOfferSuccess = (payload) => {
  return {
    type: OFFERSDATA.UPDATE_OFFER_SUCCESS,
    payload,
  };
};

export const updateOfferError = (error) => {
  return {
    type: OFFERSDATA.UPDATE_OFFER_FAILED,
    error,
  };
};

export const getBrandList = () => {
  return {
    type: PRODUCT_INFO.BRANDS_LIST,
  };
};

export const getBrandListSuccess = (payload) => {
  return {
    type: PRODUCT_INFO.BRANDS_LIST_SUCCESS,
    payload,
  };
};

export const getBrandListError = (error) => {
  return {
    type: PRODUCT_INFO.BRANDS_LIST_FAILED,
    error,
  };
};

export const getModelList = () => {
  return {
    type: PRODUCT_INFO.MODEL_LIST,
  };
};

export const getModelListSuccess = (payload) => {
  return {
    type: PRODUCT_INFO.MODEL_LIST_SUCCESS,
    payload,
  };
};

export const getModelListError = (error) => {
  return {
    type: PRODUCT_INFO.MODEL_LIST_FAILED,
    error,
  };
};

export const getVariantList = () => {
  return {
    type: PRODUCT_INFO.VARIANT_LIST,
  };
};

export const getVariantListSuccess = (payload) => {
  return {
    type: PRODUCT_INFO.VARIANT_LIST_SUCCESS,
    payload,
  };
};

export const getVariantListError = (error) => {
  return {
    type: PRODUCT_INFO.VARIANT_LIST_FAILED,
    error,
  };
};

export const updateProduct = (payload) => {
  return {
    type: PRODUCTSDATA.UPDATE_PRODUCT,
    payload,
  };
};

export const updateProductSucess = (payload) => {
  return {
    type: PRODUCTSDATA.UPDATE_PRODUCT_SUCCESS,
    payload,
  };
};

export const updateProductFailed = (error) => {
  return {
    type: PRODUCTSDATA.UPDATE_PRODUCT_FAILED,
    error,
  };
};
