import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import "../App.css";
import {
  getStateData,
  getStateLoading,
  getStateErrorr,
} from "../selectors/selector";
import CircularProgress from "@material-ui/core/CircularProgress";
import { loadScrapper } from "../actions";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    fontSize: 18,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: "3%",
    marginLeft: "3.5%",
    width: "93%",
  },
  rootpage: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function CustomizedTables() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const loading = useSelector(getStateLoading);
  const summary = useSelector(getStateData);
  const err = useSelector(getStateErrorr);

  useEffect(() => {
    dispatch(loadScrapper(1));
  }, []);
  return loading ? (
    <CircularProgress style={{ marginTop: "15%", marginLeft: "50%" }} />
  ) : (
    <div>
      <TableContainer component={Paper} className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>id</StyledTableCell>
              <StyledTableCell>Username</StyledTableCell>
              <StyledTableCell>StyledTableCellEmail</StyledTableCell>
              <StyledTableCell>Staff</StyledTableCell>
              <>Superuser</>
              <StyledTableCell>Active</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {summary.count &&
              summary.results.map((row) => (
                <StyledTableRow key={row && row.id}>
                  <StyledTableCell>{row && row.id}</StyledTableCell>
                  <StyledTableCell>{row && row.username}</StyledTableCell>
                  <StyledTableCell>{row && row.email}</StyledTableCell>
                  <StyledTableCell>{row && row.is_staff ? "Yes" : "No"}</StyledTableCell>
                  <StyledTableCell>{row && row.is_superuser ? "Yes" : "No"}</StyledTableCell>
                  <StyledTableCell>{row && row.is_active ? "Yes" : "No"}</StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          marginTop: "1%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Pagination
          className={classes.rootpage}
          count={summary.total_pages}
          color="primary"
          page={summary.current_page}
          onChange={(event, value) => {
            dispatch(loadScrapper(value));
          }}
        />
      </div>
      <div className="errorlength">
        {err && <div className="error">{err}</div>}
      </div>
    </div>
  );
}
