import { combineReducers } from "redux";
import { ScrapperReducer } from "./ScrapperReducer";
import { LogInReducer } from "./LogInReducer";
import { AddUserReducer } from "./AddUserReducer";
import { ProductsReducer } from "./ProductsReducer";
import { OffersReducer } from "./OffersReducer";

const appReducer = combineReducers({
  ScrapperReducer: ScrapperReducer,
  LogInReducer: LogInReducer,
  ProductsReducer: ProductsReducer,
  OffersReducer: OffersReducer,
  AddUserReducer: AddUserReducer,
});
const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === "USER_LOGGED_OUT") {
    state = undefined;
  }

  return appReducer(state, action);
};
export default rootReducer;
