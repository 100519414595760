import { createSelector } from "reselect";
const getCount = (state) => state.ScrapperReducer.count;
export const getStateCount = createSelector([getCount], (count) => count);

const getLoading = (state) => state.ScrapperReducer.loading;
export const getStateLoading = createSelector(
  [getLoading],
  (loading) => loading
);

const getStateLoginLoading = (state) => state.LogInReducer.loading;
export const getLoginLoading = createSelector(
  [getStateLoginLoading],
  (loading) => loading
);

const getErr = (state) => state.LogInReducer.err;
export const getStateErr = createSelector([getErr], (err) => err);

const getAddUserErr = (state) => state.AddUserReducer.err;
export const getStateAddUserErr = createSelector([getAddUserErr], (err) => err);

const getToken = (state) => state.LogInReducer.userData;
export const getStateToken = createSelector([getToken], (userData) => userData);

const getAddUser = (state) => state.AddUserReducer.userData;
export const getStateAddUser = createSelector([getAddUser], (userData) => userData);

const getData = (state) => state.ScrapperReducer.data;
export const getStateData = createSelector([getData], (data) => data);

const pageData = (state) => state.ScrapperReducer.pageDate;
export const getStatePageData = createSelector(
  [pageData],
  (userData) => userData
);

const getDate = (state) => state.ScrapperReducer.date;
export const getStateDate = createSelector([getDate], (date) => date);

const getPage = (state) => state.ScrapperReducer.page;
export const getStatePage = createSelector([getPage], (page) => page);

const getStatData = (state) => state.ScrapperReducer.stat;
export const getStat = createSelector([getStatData], (stat) => stat);

const getErrorr = (state) => state.ScrapperReducer.error;
export const getStateErrorr = createSelector([getErrorr], (error) => error);

const productsError = (state) => state.ProductsReducer.error;
export const getProductsError = createSelector(
  [productsError],
  (error) => error
);

const productsLoading = (state) => state.ProductsReducer.loading;
export const getProductsLoading = createSelector(
  [productsLoading],
  (loading) => loading
);

const productsData = (state) => state.ProductsReducer.products;
export const getProductsData = createSelector(
  [productsData],
  (products) => products
);

const productData = (state) => state.ProductsReducer.product;
export const getProductData = createSelector(
  [productData],
  (product) => product
);

const offersError = (state) => state.OffersReducer.error;
export const getOffersError = createSelector([offersError], (error) => error);

const offersLoading = (state) => state.OffersReducer.loading;
export const getOffersLoading = createSelector(
  [offersLoading],
  (loading) => loading
);

const offersData = (state) => state.OffersReducer.offers;
export const getOffersData = createSelector([offersData], (offers) => offers);

const offerData = (state) => state.OffersReducer.offer;
export const getOfferData = createSelector([offerData], (offer) => offer);

const mappedProductData = (state) => state.OffersReducer.mappedProduct;
export const getMappedProductData = createSelector(
  [mappedProductData],
  (mappedProduct) => mappedProduct
);

const offersViewFiltersData = (state) => state.OffersReducer.viewFilters;
export const getOffersViewFiltersData = createSelector(
  [offersViewFiltersData],
  (viewFilters) => viewFilters
);

const productsViewFiltersData = (state) => state.ProductsReducer.viewFilters;
export const getProductsViewFiltersData = createSelector(
  [productsViewFiltersData],
  (viewFilters) => viewFilters
);

const mapOffersError = (state) => state.ProductsReducer.mapError;
export const getMapOffersError = createSelector(
  [mapOffersError],
  (mapError) => mapError
);

const mapOffersLoading = (state) => state.ProductsReducer.mapLoading;
export const getMapOffersLoading = createSelector(
  [mapOffersLoading],
  (mapLoading) => mapLoading
);

const createProductError = (state) => state.OffersReducer.createError;
export const getCreateProductError = createSelector(
  [createProductError],
  (createError) => createError
);

const createProductLoading = (state) => state.OffersReducer.createLoading;
export const getCreateProductLoading = createSelector(
  [createProductLoading],
  (createLoading) => createLoading
);

const deleteProductError = (state) => state.ProductsReducer.deleteError;
export const getDeleteProductError = createSelector(
  [deleteProductError],
  (deleteError) => deleteError
);

const deleteProductLoading = (state) => state.ProductsReducer.deleteLoading;
export const getDeleteProductLoading = createSelector(
  [deleteProductLoading],
  (deleteLoading) => deleteLoading
);

const deleteProductSuccess = (state) => state.ProductsReducer.deleteSuccess;
export const getDeleteProductSuccess = createSelector(
  [deleteProductSuccess],
  (deleteSuccess) => deleteSuccess
);

const updateOfferError = (state) => state.OffersReducer.updateError;
export const getUpdateOfferError = createSelector(
  [updateOfferError],
  (updateError) => updateError
);

const updateOfferLoading = (state) => state.OffersReducer.updateLoading;
export const getUpdateOfferLoading = createSelector(
  [updateOfferLoading],
  (updateLoading) => updateLoading
);

const productBrands = (state) => state.ProductsReducer.brands;
export const getProductBrands = createSelector(
  [productBrands],
  (brands) => brands
);

const productModels = (state) => state.ProductsReducer.models;
export const getProductModels = createSelector(
  [productModels],
  (models) => models
);

const productVariants = (state) => state.ProductsReducer.variants;
export const getProductvariants = createSelector(
  [productVariants],
  (variants) => variants
);
